<template>
  <div class="phone">
    <div class="phone-input-wrapper">
      <div class="added-digits">+7</div>
      <input
        type="tel"
        placeholder="(___)___-__-__"
        v-model="phone"
        ref="phoneField"
        maxlength="16"
        :id="phoneId"
        @blur="blurHandler"
        @paste.prevent="onPasteContactPhone"
        @keypress="preventNoNumbers"
        v-mask="'(###)###-##-##'"
        class="phone-input modal-input"
        :class="{
          'invalid-input': invalid,
        }"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import useMaskPhone from '@hooks/useMaskPhone'
import getId from '@utils/idGenerator'
import preventNoNumbers from '@utils/preventNoNumbers'

const props = defineProps({
  phone: {
    type: String,
    required: true,
    default: '',
  },
  invalid: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { phone: propPhone, invalid, disabled } = toRefs(props)
const emit = defineEmits(['update:phone', 'blur'])
const id = getId()
const phoneId = `phone-${id}`

const phone = computed({
  get: () => propPhone.value,
  set: val => {
    emit('update:phone', val)
  },
})

const onPasteContactPhone = e => {
  preventNoNumbers(e)
  phone.value = useMaskPhone(e.clipboardData.getData('Text'))
}

const blurHandler = e => {
  emit('blur', e)
}

defineExpose({ phone, phoneId })
</script>

<style scoped lang="scss">
.phone {
  width: 100%;
}

.phone-input-wrapper {
  position: relative;

  .added-digits {
    position: absolute;
    top: 0;
    bottom: 0;

    height: fit-content;
    margin: auto 0 auto 15px;

    font-size: inherit;
    font-weight: 400;
    color: inherit;
  }

  .phone-input {
    overflow: hidden;

    width: 100%;
    padding-left: 32px !important;

    color: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
      line-height: inherit;
      color: inherit;
    }
  }
}
</style>
