function getQueryStringParameter(uri, key, value) {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'

  if (value === null) {
    const newStr = uri.replace(re, `$1`)
    const lastChar = newStr.at(-1)

    return lastChar === '&' || lastChar === '?' ? newStr.slice(0, -1) : newStr
  }

  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`)
  }

  return `${uri + separator + key}=${value}`
}

export default getQueryStringParameter
