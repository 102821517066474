import AuthService from '@services/auth'

const IS_REDIRECT_AFTER_REGISTRATION = true

export default {
  data() {
    return {
      registrationPhone: '',
      registrationFio: '',
      registrationPassword: '',
      registrationRepeatedPassword: '',
      confirmCode: '',
      registrationEmail: '',
      confirmCodeAskedMessage: '',
      confirmPhoneMessage: '',
      registrationResponseMessage: '',
      isConfirmCodeAsked: false,
      isPhoneConfirmed: false,
      isUserRegistered: false,
      isLoading: false,
      isConfirmCodeLoading: false,
      isConfirmCodeNumberLoading: false,
      codePlaceholder: 'Код из смс',
      isRequiredEmail: true,
    }
  },
  computed: {
    rawRegistrationPhone() {
      return `+7${this.registrationPhone}`
    },
    isCustomer() {
      return this.chosenRegistrationButton.id === 2 || this.chosenRegistrationButton.id === 4
    },
    isLegalEntityCusomer() {
      return this.chosenRegistrationButton.id === 4
    },
  },
  methods: {
    async sendRegisterRequest() {
      this.$v.$touch()

      if (this.$v.$invalid) return
      this.isLoading = true

      const token = await this.tokenGenerator()
      const formData = new FormData()

      formData.append('token', token)
      formData.append('User[fio]', this.registrationFio)
      formData.append('User[pass]', this.registrationPassword)
      formData.append('User[repeat]', this.registrationPassword)
      formData.append('User[type]', this.isCustomer ? 2 : this.chosenRegistrationButton.id)
      formData.append('User[phone]', this.rawRegistrationPhone)
      formData.append('User[code]', this.confirmCode)
      formData.append('User[city]', window.cityId)
      formData.append('promo_code', this.appliedPromocode)
      formData.append('User[email]', this.registrationEmail)

      // 4 - Заказчик юр. лицо
      if (this.isLegalEntityCusomer) {
        formData.append('User[legal_entity_type]', 1)

        const dateLegalEntity = new Date().toLocaleString('ru').replace(/\./g, '-').replace(',', '')

        formData.append('User[legal_entity_type_change]', dateLegalEntity)
      }

      try {
        await AuthService.registerUser(formData)

        if (!IS_REDIRECT_AFTER_REGISTRATION) return

        setTimeout(() => {
          document.location = '/'
        }, 1000)
      } catch (e) {
        console.error(e)
        this.$toast.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
    fieldsSomeError(fields) {
      return fields.some(field => this.$v[field].$invalid)
    },
    async sendConfirmNumber() {
      this.$v.registrationPhone.$touch()

      this.isConfirmCodeLoading = true

      try {
        const token = await this.tokenGenerator()

        const { data: response } = await AuthService.sendConfirmNumber({
          token,
          phone: this.rawRegistrationPhone,
        })

        this.setCountDown()
        this.isConfirmCodeAsked = response.success
        this.confirmCodeAskedMessage = response.message
        this.codePlaceholder = response.messagePlaceholder
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        this.isConfirmCodeLoading = false
      }
    },
    async getIsEmailRequired() {
      this.isLoading = true

      try {
        const res = await AuthService.getIsEmailRequired()

        this.isRequiredEmail = res.message
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
    async confirmNumber() {
      this.isConfirmCodeNumberLoading = true

      try {
        const { data: response } = await AuthService.confirmNumber({
          phone: this.rawRegistrationPhone,
          code: this.confirmCode,
        })

        this.isPhoneConfirmed = response.success
        this.confirmPhoneMessage = response.message
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        this.isConfirmCodeNumberLoading = false
      }
    },
    async callConfirmActions() {
      this.isConfirmCodeLoading = true
      await this.setTempUser()
      await this.sendConfirmNumber()
    },

    checkConfirmCode() {
      if (this.$v.confirmCode.$invalid) return

      this.confirmNumber()
    },
  },

  created() {
    this.getIsEmailRequired()
  },
}
