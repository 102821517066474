<template>
  <div class="validation-wrapper">
    <input
      type="text"
      name="fio"
      class="modal-input"
      placeholder="Ваше Имя"
      @blur="$emit('blur')"
      v-model="fio"
    />
    <transition name="fade" tag="div" mode="out-in">
      <div v-if="isMaxError" class="invalid-feedback">Максимальная длина - 30 символов</div>
      <div v-if="requiredError" class="invalid-feedback">Обязательное поле</div>
    </transition>
  </div>
</template>

<script>
import onlyCyrillic from '@hooks/useCyryllic'

export default {
  props: {
    registrationFio: {
      type: String,
      required: true,
    },
    isMaxError: {
      type: Boolean,
      required: true,
    },
    requiredError: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    fio: {
      get() {
        return this.registrationFio
      },
      set(val) {
        this.$emit('update:registrationFio', val)
      },
    },
  },

  watch: {
    fio() {
      this.fio = onlyCyrillic(this.fio)
    },
  },
}
</script>
