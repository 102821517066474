// @ts-check
import { AJAX_REQUEST_HEADERS } from '@constants'
import getAxiosClient from '../axiosClient'

const axiosClient = getAxiosClient({
  axiosConfig: {
    baseURL: '/eApis/frontend/auth',
    headers: AJAX_REQUEST_HEADERS.headers,
  },
})

class AuthService {
  /**
   * @param {Object} data awaiting form object
   * @param {string} data.phone - phone
   * @param {string} data.fio - fio
   * @param {string} data.pass - password
   */
  static async setTempUser(data) {
    const response = await axiosClient.post('/SetTempUser', data)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  static async getTempUser() {
    /** @type {{data: {success: true, data: null | Record<string, any> } | {success: false, message: string}}} */
    const res = await axiosClient.get('/GetTempUser')
    const { success, data, message } = res.data

    if (!success) throw new Error(message || 'Ошибка при получении данных формы регистрации')
    return data
  }

  /**
   * @param {Object} data awaiting form object
   * @param {string} data.name - user name
   * @param {string} data.phone phone number
   */
  static async sendRecoveryCode(data) {
    const response = await axiosClient.post('/recoverySendCode', data)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  /**
   * @param {Object} data awaiting form object
   * @param {string} data.username - user name
   * @param {string} data.code recovery cide
   * @param {string} data.password password
   * @param {string} data.passwordRepeat password
   */
  static async recoveryPassword(data) {
    const response = await axiosClient.post('/recoveryPassword', data)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  /**
   * @param {Object} data awaiting form object
   * @param {string} data.promo_code - promo
   */
  static async checkPromocode(data) {
    const response = await axiosClient.post('/promocodeIsValid', data)

    if (!response.data.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response.data
  }

  static async registerUser(data) {
    const response = await axiosClient.post('/register', data)

    if (!response.data?.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response
  }

  static async sendConfirmNumber(data) {
    const response = await axiosClient.post('/sendConfirmNumber', data)

    if (!response.data?.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response
  }

  static async confirmNumber(data) {
    const response = await axiosClient.post('/confirmNumber', data)

    if (!response.data?.success) throw new Error(response.data.message || 'Ошибка при запросе')
    return response
  }

  /**
   * Получение флага необходима ли почта для регистрации
   * @returns {Promise<{data: boolean}>}
   */
  static async getIsEmailRequired() {
    /** @type {{data: {"success":boolean,"message":false|string}}} */
    const response = await axiosClient.get('/emailIsRequired')

    return response.data
  }
}

export default AuthService
