<template>
  <div class="registration-type">
    <div class="validation-wrapper">
      <div class="modal-buttons">
        <button
          v-for="registerType in registrationTypes"
          class="reg-type"
          :class="registerType.id === choosedRegistrationTypeId ? 'chosen' : null"
          @click.prevent="$emit('setRegistrationButton', registerType)"
          :key="registerType.id"
          v-html="registerType.name"
        ></button>
      </div>
      <transition name="fade" tag="div" mode="out-in">
        <div v-if="isTypeError" class="invalid-feedback">Укажите тип регистрации</div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    registrationTypes: {
      type: Array,
      required: true,
    },
    isTypeError: {
      type: Boolean,
      required: true,
    },
    choosedRegistrationTypeId: {
      type: Number,
      required: true,
    },
  },
}
</script>
