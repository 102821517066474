<template>
  <div class="input-with-button">
    <div class="validation-wrapper">
      <PhoneField
        :phone="phone"
        :invalid="isIncorrectPhoneNumberError"
        @update:phone="phone = $event"
        @blur="$emit('blur')"
      />
      <transition name="fade" tag="div" mode="out-in">
        <div v-if="isIncorrectPhoneNumberError" class="invalid-feedback">
          Введите корректный номер телефона
        </div>
      </transition>
    </div>
    <button
      class="confirm"
      @click.prevent="$emit('confirmNumber')"
      :disabled="isConfirmBtnDisabled"
    >
      <Cooldown v-if="timeLeftConfirmButton > 0" :timeLeft="timeLeftConfirmButton" />
      <Spinner v-else-if="isConfirmCodeLoading" />
      <span v-else>Подтвердить</span>
    </button>
    <transition name="fade" tag="div" mode="out-in">
      <div
        v-if="confirmCodeAskedMessage"
        :class="isConfirmCodeAsked ? 'success-feedback' : 'invalid-feedback'"
      >
        {{ confirmCodeAskedMessage }}
      </div>
    </transition>
  </div>
</template>

<script>
import Cooldown from '@features/UI/Cooldown.vue'
import PhoneField from '@features/PhoneInput.vue'
import Spinner from '@features/UI/FontAwesomeSpinner.vue'

export default {
  components: { PhoneField, Spinner, Cooldown },
  props: {
    isIncorrectPhoneNumberError: {
      type: Boolean,
      required: true,
    },
    registrationPhone: {
      type: String,
      required: true,
    },
    isConfirmBtnDisabled: {
      type: Boolean,
      required: true,
    },
    isConfirmCodeAsked: {
      type: Boolean,
      required: true,
    },
    timeLeftConfirmButton: {
      type: Number,
      required: true,
    },
    confirmCodeAskedMessage: {
      type: String,
      required: true,
    },
    isConfirmCodeLoading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    phone: {
      get() {
        return this.registrationPhone
      },
      set(val) {
        this.$emit('update:registrationPhone', val)
      },
    },
  },
}
</script>
