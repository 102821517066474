<template>
  <div class="password-input-container">
    <input
      type="password"
      v-if="!isShowPassword"
      name="password"
      :placeholder="props.placeholder"
      :class="className"
      v-model="passwordModel"
      v-bind="attrs"
      v-on="listeners"
    />
    <input
      v-else
      type="text"
      name="password"
      :placeholder="props.placeholder"
      :class="className"
      v-model="passwordModel"
      v-bind="attrs"
      v-on="listeners"
    />
    <button
      type="button"
      class="show-password-button"
      @click.prevent="isShowPassword = !isShowPassword"
    >
      <img :src="currentEyeIconSrc" :class="{ showed: isShowPassword }" alt="show password icon" />
    </button>
  </div>
</template>

<script>
import { omit } from 'lodash'

export default {
  inheritAttrs: false,
  computed: {
    listeners() {
      return omit(this.$listeners, ['input'])
    },
  },
}
</script>

<script setup>
/* eslint-disable import/first */
import { ref, computed, useAttrs } from 'vue'

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  className: {
    type: [String, Array, Object],
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Введите пароль',
  },
})

const emit = defineEmits(['input'])
const attrs = useAttrs()
const isShowPassword = ref(false)

const passwordModel = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('input', value)
  },
})

const currentEyeIconSrc = computed(() =>
  isShowPassword.value
    ? '/images/cabinet/hidden_eye_hide_icon.svg'
    : '/images/cabinet/eye_visible_unhide_icon.svg'
)
</script>

<style scoped lang="scss">
.password-input-container {
  position: relative;
}

.show-password-button {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  width: 24px;
  height: 24px;

  background: none;
  border: none;
  outline: none;

  &:focus-within {
    outline: none;
  }
}
</style>
