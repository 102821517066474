import useMaskPhone from '@hooks/useMaskPhone'
import { mustBePhoneNumber } from '@utils/phoneValidation'

export default {
  methods: {
    mustBePhoneNumber,
    onBlurContactPhone(e, field) {
      if (e.target.value === '(') {
        e.target.value = ''
      }

      this.$v[field].$touch()
    },

    /**
     * Метод валидации телефона для нового типа валдиации с миксином mixins/validationMixin
     *
     * @param {Event} e объект события
     * @param {string} fieldName названия поля телефона
     */
    onBlurPhone(e, fieldName) {
      if (e.target.value === '(') {
        e.target.value = ''
      }

      // Из validationMixin
      this.touchField(fieldName)
    },

    onFocusContactPhone(e) {
      if (e.target.value === '') {
        e.target.value = '('
      }
    },
    onPasteContactPhone(e, field) {
      this[field] = useMaskPhone(e.clipboardData.getData('Text'))
      this.$v[field].$touch()
    },
  },
}
