function checkRouteActions() {
  const params = new URLSearchParams(window.location.search)
  const action = params.get('action')
  const deeplink = params.get('deeplink')

  return { action, deeplink }
}

/**
 * Ожидает функцию, которая выполнится с аргументом - объектом {action, deeplink}
 * @param {Function} handler
 */
const userRouteAction = handler => {
  const { action, deeplink } = checkRouteActions()

  handler({ action, deeplink })
}

export default userRouteAction
