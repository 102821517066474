<template>
  <transition name="fade" tag="div" mode="out-in">
    <div class="validation-wrapper">
      <input
        type="text"
        class="modal-input"
        placeholder="Промокод (если есть)"
        v-model="promocode"
        @input="checkPromo"
        @paste="checkPromo"
      />
      <transition name="fade" tag="div" mode="out-in">
        <div v-if="showAppliedPromocode" class="success-feedback">
          Промокод <b>{{ appliedPromocode }}</b> успешно применен!
        </div>
        <div v-else-if="showPromoError" class="invalid-feedback">
          {{ promoError }}
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import UserService from '@services/auth/api'

export default {
  name: 'promocode',
  data() {
    return {
      promoError: false,
    }
  },
  computed: {
    ...mapState(['isPromoFieldShowed', 'promocode']),
    appliedPromocode: {
      get() {
        return this.$store.state.appliedPromocode
      },
      set(value) {
        this.$store.commit('updateModalData', {
          key: 'appliedPromocode',
          value,
        })
      },
    },
    promocode: {
      get() {
        return this.$store.state.promocode
      },
      set(value) {
        this.$store.commit('updateModalData', {
          key: 'promocode',
          value,
        })
      },
    },
    showPromoError() {
      return this.promoError && this.promocode.length > 3
    },
    showAppliedPromocode() {
      return this.appliedPromocode && !this.showPromoError && this.promocode.length > 3
    },
  },
  methods: {
    ...mapMutations(['updateModalData', 'setPromoFieldState']),
    ...mapActions(['checkPromo']),
    async checkPromo() {
      const formData = new FormData()

      formData.append('promo_code', this.promocode)

      try {
        await UserService.checkPromocode(formData)
        this.updateModalData({ key: 'appliedPromocode', value: this.promocode })
        this.promoError = false
      } catch (e) {
        this.promoError = e.message
        this.updateModalData({ key: 'appliedPromocode', value: '' })
      }
    },
  },
}
</script>

<style></style>
